/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-apex chart
import BasicMixedChart from "examples/Charts/MixedChart/BasicMixedChart";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function MixedChart({ title, description, data, options }) {
  const renderChart = (
    <VuiBox>

      {title || description ? (
        <VuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <VuiBox mb={1}>
              <VuiTypography 
                variant="lg" 
                color="white" 
                fontWeight="500" 
                mb={2} // Add margin bottom to the title
                sx={{
                  position: "absolute",
                  top: "1rem",
                  left: "2rem",
                  fontSize: "1.3rem",
                }}
              >
                {title}
              </VuiTypography>
            </VuiBox>
          )}
          <VuiBox mt={4}>
            <VuiTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      ) : null}

      {useMemo(
        () => (
          <VuiBox sx={{ minHeight: "300px", height: "150px", width: "100%" }}>
            <BasicMixedChart mixedChartData={data} mixedChartOptions={options} />
          </VuiBox>
        ),
        [data, options]
      )}
    </VuiBox>
  );

  return title || description ? <Card style={{height: "100%", backgroundColor: "rgba(9,13,42,1.0)"}}>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of MixedChart
MixedChart.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the MixedChart
MixedChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default MixedChart;
