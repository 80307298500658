/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data
import MishandlingRisks from "./components/MishandlingRisks";
import WeatherInfo from "./components/WeatherInfo"
import DefaultCell from "layouts/ecommerce/orders/monitoring/components/DefaultCell";

import Table from "examples/Tables/Table";
import DataTable from "examples/Tables/DataTable";

import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import { addDays }  from 'date-fns';

import { get_top_routes_at_risk_data,
  get_top_routes_at_risk_data_with_time_limit,
  get_two_days_top_routes_at_risk_data_with_time_limit
} from "../../../graphql/get_data";


function AviAi() {
  const { values } = breakpoints;

  const [newRows, setNewRows] = useState([]);
  const [newCols, setNewCols] = useState([]);
  const [data, setData] = useState({ columns: [], rows: []});
  const [date, setDate] = useState(utcToZonedTime(new Date(), "Europe/Amsterdam"));

  let [predictionData, setPredictionData] = useState([]);
  let [preventedData, setPreventedData] = useState([]);

  let date_list = [];

  async function fetchAndUpdateData() {

    const columnNameMap = new Map([
      ["arrival_flight_number_full", "Flight"],
      //["destination_airport", "Destination Airport"],
      ["bags_at_risk", "Bags At Risk"],
      ["estimated_arrival", "Est. Arrival"],
      ["gate", "Gate"],
      ["handlers_avialable", "Handlers"],
      ["aircraft_equipment", "Equipment"],
      ["emissions", "Emissions"]
    ]);

    //const apiData = await get_top_routes_at_risk_data(15);
    var apiData;
    if (date === null) {
      apiData = await get_two_days_top_routes_at_risk_data_with_time_limit(15);
    } else {
      apiData = await get_two_days_top_routes_at_risk_data_with_time_limit(15, date, 21600, Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    // Use local timezone here, because we want the time specified to be local time
    
    let columns = [], rows = [];

    columnNameMap.forEach((display_name, keys) => {
      columns.push({ "name": keys, "display": display_name, align: "center",
        Header: display_name,
        accessor: keys,
        Cell: ({ value }) => <DefaultCell value={value} />
      });
    })

    rows = apiData.map((row) => {
      let rx = /(.*) \((.*)\)/g;
      var res = rx.exec(row["arrival_flight_number"]);
      return {
        origin_airport: res[2],
        gate: row["arrival_gate"],
        handlers_avialable: row["handlers_avialable"],
        bags_at_risk: row["bags_at_risk"],
        estimated_arrival: row["estimated_arrival"],
        arrival_flight_number: res[1],
        arrival_flight_number_full: row["arrival_flight_number"],
        aircraft_equipment: row["aircraft_equipment"],
        hasBorder: true,
        emissions: row["emissions"],
        date: row["date"]
      };
    })

    rows.sort((a, b) => b["bags_at_risk"] - a["bags_at_risk"] );

    setNewCols(columns);
    setNewRows(rows);
    setData({ columns: columns, rows: rows });;
  }

  useEffect(() => {
    fetchAndUpdateData();
  }, [date]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={-1} mb={3}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <VuiBox mb={-1} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Overview
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid container spacing={2} mt="2px">
            <Grid item width="50%">
              <MishandlingRisks date={date} />
            </Grid>
            <Grid item width="50%">
              <WeatherInfo />
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
      {/* 
      <Card>
        /<VuiTypography variant="lg" color="white" fontWeight="bold" mb="1rem">
          Top Routes at Risk
        </VuiTypography>
        <VuiBox sx={{padding: "1rem 2rem 0"}}>
          <DataTable table={data}  title="Top Routes at Risk"
                  showTotalEntries={false}
                  entriesPerPage={false}/>
        </VuiBox>
        </Card>
      */}
      <Table 
        columns={newCols} 
        rows={newRows} 
        title="Top Routes at Risk"
        date={date}
        setDate={(x)=>setDate(x)}  
      />
      <Footer />
    </DashboardLayout>
  );
}

export default AviAi;
