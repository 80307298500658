export const mishandling_aggregate_query = /* GraphQL */ `
    query MishandlingAggregateQuery($date: String!) {
        getYxuAetherRiskHandlingAggregateDemoModel(date: $date) {
            date
            mishandlings
            prevented
        }
    }
`;

export const mishandling_risk_query = /* GraphQL */ `
    query getRisk($prio: String!, $limit: Int) {
        bagRiskByPriority(priority: $prio, sortDirection: DESC, limit: $limit) {
            items {
                arrival_flight_number
                arrival_gate
                bag_id
                createdAt
                date
                departure_flight_number
                departure_gate
                emissions
                estimated_arrival
                estimated_arrival_timestamp
                estimated_departure
                estimated_arrival_timestamp
                priority
                transfer_time
                updatedAt
            }
        }
    }
`;

export const mishandling_risk_date_query = /* GraphQL */ `
query MyQuery($date: String = "", $between: [Int], $limit: Int) {
    bagRiskByDate(date: $date, sortDirection: ASC, filter: {estimated_arrival_timestamp: {between: $between}}, limit: $limit) {
      items {
        arrival_flight_number
        arrival_gate
        bag_id
        createdAt
        date
        departure_flight_number
        departure_gate
        emissions
        estimated_arrival
        estimated_arrival_timestamp
        estimated_departure
        estimated_departure_timestamp
        priority
        transfer_time
        updatedAt
      }
      nextToken
    }
  }

`;

export const mishandling_risk_flight_query = /* GraphQL */ `
    query RisksByFlightQuery($arrival_flight_number: String = "", $date: String = "", $limit: Int = 10) {
        mishandlingsByFlightNumber(arrival_flight_number: $arrival_flight_number, date: {eq: $date}, limit: $limit, sortDirection: DESC) {
        items {
            arrival_flight_number
            arrival_gate
            bag_id
            createdAt
            date
            departure_flight_number
            departure_gate
            emissions
            estimated_arrival
            priority
            transfer_time
            updatedAt
        }
        }
    }
`;


export const top_routes_at_risk_query = /* GraphQL */ `
    query getRiskyRoutes($date: String!, $limit: Int) {
        routesByDate(date: $date, sortDirection: DESC, limit: $limit) {
            nextToken
                date
                aircraft_equipment
                arrival_flight_number
                arrival_gate
                bags_at_risk
                createdAt
                emissions
                estimated_arrival
                handlers_avialable
                origin_airport
                updatedAt
            }
        }
    }
`;

export const bag_info_query = /* GraphQL */ `
    query GetBagInfo($bag_id: String!) {
        getYxuAetherBaggageInfoDemoModel(bag_id: $bag_id) {
        actual_transfer_time
        arrival_date
        arrival_flight_number
        arrival_gate
        bag_color
        bag_id
        bag_size
        bag_type
        bag_weight
        departure_date
        departure_flight_number
        departure_gate
        estimated_arrival
        estimated_departure
        estimated_transfer_time
        loading_position
        pnr
        resolution_code
        resolution_instructions
        resolution_name
        emissions
        }
    }
`;

// TODO: use list instead of this
export const mishandling_risk_filter_flight_number_query = /* GraphQL */ `
    query RiskyBagsWithFlightFilterQuery ($priority: String!, $flight_number: String) {
        bagRiskByPriority(priority: $priority, filter: {arrival_flight_number: {contains: $flight_number}}) {
        nextToken
        items {
            arrival_flight_number
            arrival_gate
            bag_id
            createdAt
            date
            departure_flight_number
            departure_gate
            emissions
            estimated_arrival
            estimated_arrival_timestamp
            estimated_departure
            estimated_departure_timestamp
            priority
            transfer_time
            updatedAt
        }
        }
    }
`;

export const top_routes_at_risk_with_timestamp_filter_query = /* GraphQL */ `
query MyQuery($date: String!, $between: [Int], $limit: Int, $nextToken: String) {
    routesByDate(date: $date, filter: {estimated_arrival_timestamp: {between: $between}}, sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        aircraft_equipment
        arrival_flight_number
        arrival_gate
        bags_at_risk
        createdAt
        date
        emissions
        estimated_arrival
        handlers_avialable
        origin_airport
        updatedAt
        estimated_arrival_timestamp
      }
    }
  }  
`;