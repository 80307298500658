/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import bgBasic from "assets/images/background-basic-auth.png";
import background from "assets/images/background.png";
import morning from "assets/images/morning.png";

import afternoon from "assets/images/afternoon.png";
import evening from "assets/images/evening.png";


import { useAuth0 } from "@auth0/auth0-react";

function Welcome() {
  const [rememberMe, setRememberMe] = useState(false);
  const {  user} = useAuth0();

    var today = new Date();
    const time = today.getHours();
    //console.log(time)

  useEffect(()=>{

    var today = new Date();
    const time = today.getHours();
    //console.log(time)
  }, [])


  return (
    <div style={{ 
      width: "100vw",
      height: "100vh", 
      textAlign: "center", color: "white",
      backgroundImage: `url(${background})`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "2rem",
    }} 
      onClick={() => window.location.href="/dashboard"}>
      {"Good " + (time <12 && "Morning" || time <18 && "Afternoon" || "Evening")}, 
      {user ? " " + user.name : ''}
      <img style={{paddingLeft: "1rem", width: "3rem"}}src= {time <12 && morning || time < 18 && afternoon || evening} />
    </div>
  );
}

export default Welcome;
