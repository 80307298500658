import React, { useEffect, useState } from "react";
import { Card, Stack, Grid } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import WhiteLightning from "assets/images/shapes/white-lightning.svg";
import GreenLightning from "assets/images/shapes/green-lightning.svg";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import carProfile from "assets/images/shapes/car-profile.svg";
import BasicLineChart from "examples/Charts/LineCharts/BasicLineChart/index";
import {
  lineChartDataProfile1,
  lineChartDataProfile2,
} from "layouts/pages/profile/profile-overview/data/lineChart";
import {
  lineChartOptionsProfile2,
  lineChartOptionsProfile1,
} from "layouts/pages/profile/profile-overview/data/lineChart";
import RealTimeForecaster from "./RealTimeForecaster";
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

var requestOptions = {
  method: "GET",
  redirect: "follow",
};

const typographyStyle1 = {
  color: "text",
  variant: "caption",
  fontWeight: "light",
  fontFamily: "Arial, sans-serif",
  mt: "0.rem",
  mb: "0.rem",
  ml: "0.rem",
  mr: "0rem",
  fontSize: "0.85rem",
};

const typographyStyle2 = {
  color: "white",
  variant: "body2",
  fontWeight: "light",
  // fontFamily: "Arial, sans-serif",
  mt: "0.rem",
  mb: "0.rem",
  ml: "0.rem",
  mr: "0rem",
  fontSize: "1rem",
};

const vuiBoxStyle = {
  display: "flex",
  p: "0 1rem .5rem 1rem",
  color: "white",
  bgColor: "black",
  variant: "gradient",
  borderRadius: "20px",
  height: "100%",
  width: "100%",
  mt: "1rem",
  mb: "0rem",
  mr: "0.5rem",
  ml: "1rem",
  background: 'linear-gradient(90deg, #3D3464 0%, #222143 100%)',
};




function convertEpochToTime(epochTime) {
  const date = new Date(0); 
  date.setUTCSeconds(epochTime);
  let hours = date.getHours() + 7;
  let minutes = date.getMinutes();
  if (hours >= 24) {
    hours -= 24;
  }
  const ampm = hours >= 12 ? "PM" : "AM";
  // Convert to 12-hour format
  hours %= 12;
  hours = hours || 12; // 0 should be displayed as 12
  minutes = minutes < 10 ? "0" + minutes : minutes;
  // console.log(hours + ":" + minutes + " " + ampm);
  
  return hours + ":" + minutes + " " + ampm;
}

function WeatherInfo() {
  const { gradients, info } = colors;
  const { cardContent } = gradients;
  const [weatherData, setWeatherData] = useState({});

  useEffect(() => {
    fetch(
      // CDT lat = 52.3105, lon = -4.7683
      // CEST lat = 52.3676, lon = 4.7683
      "https://api.openweathermap.org/data/2.5/weather?lat=52.3105&lon=4.7683&appid=c3b264ded205bffeaa8bac1b4318a2a5&units=metric",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWeatherData(result);
      })
      .catch((error) => console.log("error", error));
  }, [setWeatherData]);

  const amsterdamTimeZone = 'Europe/Amsterdam';
  const currentDate = new Date();
  const currentDateTimeInAmsterdam = utcToZonedTime(currentDate, amsterdamTimeZone);
  let hours = currentDateTimeInAmsterdam.getHours();
  let minutes = currentDateTimeInAmsterdam.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time_string = hours + ":" + minutes + " " + ampm;
  return (
    <Card sx={{ height: "100%", justifyContent: "space-evenly",
    display: "flex",
    alignItems: "center", 
    backgroundColor: "rgba(9,13,42,1.0)"}}>
        
        <VuiTypography 
          variant="lg" 
          color="white" 
          fontWeight="500" 
          mb="2rem"  // Increase the font size here
          sx={{
            position: "absolute",
            top: "1rem",
            left: "2rem",
            fontSize: "1.3rem",
          }}
        >
          AMS - Amsterdam Schiphol Airport
        </VuiTypography>


        <Stack
          background="#fff"
          sx={({ breakpoints }) => ({
            justifyContent: "space-evenly",
            width: "100%",
            [breakpoints.up("sm")]: {
              flexDirection: "row",
            },
            [breakpoints.up("md")]: {
              flexDirection: "row",

            },
            [breakpoints.only("xl")]: {
              flexDirection: "row",

            },
          })}
        >

          
        <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "3rem" }}>
          <RealTimeForecaster percentage={60} />
          <VuiBox sx={{ textAlign: "center", marginTop: "0.7rem" }}>
            <VuiTypography color="white" variant="button" fontWeight="regular" fontSize="1rem">
              +6 Hours Forecast
            </VuiTypography>
          </VuiBox>
        </VuiBox>








          


          {/* <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={({ breakpoints }) => ({
              marginBottom: "2rem",
              [breakpoints.only("sm")]: {
                alignItems: "center",
              },
            })}
            alignItems="center"
          >
            <VuiBox sx={{ position: "relative", display: "inline-flex" }}>
<RealTimeForecaster percentage={65}/>
              <CircularProgress
                variant="determinate"
                value={29}
                size={280}
                color="success"
                sx={{
                  left: 0,
                  "& .MuiCircularProgress-svg": {
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 10px 12px rgba(255,255,255,0.1)",
                  },
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
              ></CircularProgress>
              
              <VuiBox
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />

              <VuiBox
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >

                <VuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <VuiTypography
                    color="white"
                    variant="h2"
                    mt="6px"
                    fontWeight="bold"
                    mb="4px"
                  >
                    29%
                  </VuiTypography>
                  <VuiTypography color="text" variant="caption">
                    Prevention
                  </VuiTypography>
                </VuiBox>
              </VuiBox>


              
            </VuiBox>

            <VuiBox
              display="flex"
              justifyContent="center"
              flexDirection="column"
              mt={1}
              sx={{ textAlign: "center" }}
            >
              <VuiTypography color='white' variant='lg' fontWeight='bold' mb='2px' mt='18px'>
							+6 Hours Forecast
							</VuiTypography> 
              <VuiTypography color="text" variant="button" fontWeight="regular">
                +6 Hours Forecast
              </VuiTypography>
            </VuiBox>
          </VuiBox> */}

          {/* <div style={{
            width: "40%" // Adding a filler for the circle
            }}>  
          </div> */}
          


          <div style={{width: "66%"}}>
            <div style={{display: "flex", marginTop: "2rem" }}>
              <VuiBox
                display={vuiBoxStyle.display}
                p={vuiBoxStyle.p}
                color={vuiBoxStyle}
                bgColor={vuiBoxStyle.bgColor}
                variant={vuiBoxStyle.variant}
                borderRadius={vuiBoxStyle.borderRadius}
                height={vuiBoxStyle.height}
                width={vuiBoxStyle.width}
                mt={vuiBoxStyle.mt}
                mb={vuiBoxStyle.mb}
                mr={vuiBoxStyle.mr}
                ml={vuiBoxStyle.ml}
                style={{background: vuiBoxStyle.background}}
              >
                <VuiBox>
                  <VuiTypography
                    color={typographyStyle1.color}
                    variant={typographyStyle1.variant}
                    fontWeight={typographyStyle1.fontWeight}
                    fontFamily={typographyStyle1.fontFamily}
                    mt={typographyStyle1.mt}
                    mb={typographyStyle1.mb}
                    ml={typographyStyle1.ml}
                    mr={typographyStyle1.mr}
                    style={{ fontSize: typographyStyle1.fontSize }}
                  >
                    Time 
                  </VuiTypography>
                  <VuiTypography
                    color={typographyStyle2.color}
                    variant={typographyStyle2.variant}
                    fontWeight={typographyStyle2.fontWeight}
                    mt={typographyStyle2.mt}
                    mb={typographyStyle2.mb}
                    ml={typographyStyle2.ml}
                    mr={typographyStyle2.mr}
                    style={{ fontSize: typographyStyle2.fontSize }}
                  >
                    {time_string}
                    {/* {convertEpochToTime(weatherData?.dt  + weatherData?.timezone)}  */}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>

              <VuiBox
                display={vuiBoxStyle.display}
                p={vuiBoxStyle.p}
                color={vuiBoxStyle}
                bgColor={vuiBoxStyle.bgColor}
                variant={vuiBoxStyle.variant}
                borderRadius={vuiBoxStyle.borderRadius}
                height={vuiBoxStyle.height}
                width={vuiBoxStyle.width}
                mt={vuiBoxStyle.mt}
                mb={vuiBoxStyle.mb}
                mr={vuiBoxStyle.mr}
                ml={vuiBoxStyle.ml}
                style={{background: vuiBoxStyle.background}}
              >
                <VuiBox>
                  <VuiTypography
                    color={typographyStyle1.color}
                    variant={typographyStyle1.variant}
                    fontWeight={typographyStyle1.fontWeight}
                    fontFamily={typographyStyle1.fontFamily}
                    mt={typographyStyle1.mt}
                    mb={typographyStyle1.mb}
                    ml={typographyStyle1.ml}
                    mr={typographyStyle1.mr}
                    style={{ fontSize: typographyStyle1.fontSize }}
                  >
                    Weather
                  </VuiTypography>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <VuiTypography
                      color={typographyStyle2.color}
                      variant={typographyStyle2.variant}
                      fontWeight={typographyStyle2.fontWeight}
                      mt={typographyStyle2.mt}
                      mb={typographyStyle2.mb}
                      ml={typographyStyle2.ml}
                      mr={typographyStyle2.mr}
                      style={{ fontSize: typographyStyle2.fontSize }}
                    >
                      {Math.round(weatherData?.main?.temp)}
                      <span>&deg;C</span>
                    </VuiTypography>
                    <div style={{ paddingLeft: "1rem", alignSelf: "center" }}>
                      <VuiTypography
                        color="white"
                        variant="caption"
                        display="block"
                        fontWeight={typographyStyle1.fontWeight}
                        fontSize="0.75rem"
                      >
                        {/* {weatherData?.weather?.[0].main} */}
                      </VuiTypography>
                    </div>
                  </div>
                </VuiBox>
              </VuiBox>
            </div>


            

            <div style={{ display: "flex", marginTop: "1rem"}}>
            <VuiBox
                display={vuiBoxStyle.display}
                p={vuiBoxStyle.p}
                color={vuiBoxStyle}
                bgColor={vuiBoxStyle.bgColor}
                variant={vuiBoxStyle.variant}
                borderRadius={vuiBoxStyle.borderRadius}
                height={vuiBoxStyle.height}
                width={vuiBoxStyle.width}
                mt={vuiBoxStyle.mt}
                mb={vuiBoxStyle.mb}
                mr={vuiBoxStyle.mr}
                ml={vuiBoxStyle.ml}
                style={{background: vuiBoxStyle.background}}
              >
                <VuiBox>
                  <VuiTypography
                    color={typographyStyle1.color}
                    variant={typographyStyle1.variant}
                    fontWeight={typographyStyle1.fontWeight}
                    fontFamily={typographyStyle1.fontFamily}
                    mt={typographyStyle1.mt}
                    mb={typographyStyle1.mb}
                    ml={typographyStyle1.ml}
                    mr={typographyStyle1.mr}
                    style={{ fontSize: typographyStyle1.fontSize }}
                  >
                    Visibility
                  </VuiTypography>
                  <VuiTypography
                    color={typographyStyle2.color}
                    variant={typographyStyle2.variant}
                    fontWeight={typographyStyle2.fontWeight}
                    mt={typographyStyle2.mt}
                    mb={typographyStyle2.mb}
                    ml={typographyStyle2.ml}
                    mr={typographyStyle2.mr}
                    style={{ fontSize: typographyStyle2.fontSize }}
                  >
                    {"> "}
                    {Math.round(weatherData?.visibility * 0.001)}
                    {" Kms"}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
              <VuiBox
                display={vuiBoxStyle.display}
                p={vuiBoxStyle.p}
                color={vuiBoxStyle}
                bgColor={vuiBoxStyle.bgColor}
                variant={vuiBoxStyle.variant}
                borderRadius={vuiBoxStyle.borderRadius}
                height={vuiBoxStyle.height}
                width={vuiBoxStyle.width}
                mt={vuiBoxStyle.mt}
                mb={vuiBoxStyle.mb}
                mr={vuiBoxStyle.mr}
                ml={vuiBoxStyle.ml}
                style={{background: vuiBoxStyle.background}}
              >
                <VuiBox>
                  <VuiTypography
                    color={typographyStyle1.color}
                    variant={typographyStyle1.variant}
                    fontWeight={typographyStyle1.fontWeight}
                    fontFamily={typographyStyle1.fontFamily}
                    mt={typographyStyle1.mt}
                    mb={typographyStyle1.mb}
                    ml={typographyStyle1.ml}
                    mr={typographyStyle1.mr}
                    style={{ fontSize: typographyStyle1.fontSize }}
                  >
                    Remarks
                  </VuiTypography>
                  <VuiTypography
                    color={typographyStyle2.color}
                    variant={typographyStyle2.variant}
                    fontWeight={typographyStyle2.fontWeight}
                    mt={typographyStyle2.mt}
                    mb={typographyStyle2.mb}
                    ml={typographyStyle2.ml}
                    mr={typographyStyle2.mr}
                    style={{ fontSize: typographyStyle2.fontSize }}
                  > Cloudy
                    {/* {weatherData?.weather?.[0].description
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ")} */}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </div>
          </div>





        </Stack>
      {/* </VuiBox> */}


    </Card>
  );
}

export default WeatherInfo;
