/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Modal from "@mui/material/Modal";
import palette from "assets/theme/base/colors";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Airplane from "assets/images/airplane.png";

import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import * as AWS from "aws-sdk";

const docClient = new AWS.DynamoDB.DocumentClient({
  region: "us-east-2",
  accessKeyId: "AKIAQESVFYHAS46R67EK",
  secretAccessKey: "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",
  dynamoDbCrc32: false,
});

let params = {
  TableName: "Ariella_Dev_realTime_Baggage_Monitoring",
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "80vh",
};

const styleModal = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  boxShadow: 24,
  p: 4,
  margin: '0 !important',
  backgroundColor: palette.secondary.main,
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const center2 = {
  lat: -4.745,
  lng: -39.523,
};

function Maps() {
  const [data, setData] = useState();
  const [infoWindowOpen, setInfoWindowOpen] = useState({});
  const [markerVisible, setMarkerVisible] = useState({});
  const [allMarkersVisible, setAllMarkersVisible] = useState(true);
  const [map, setMap] = useState(null);

  //modal
  const [openBeaconInfo, setOpenBeaconInfo] = useState(false);
  const [modalData, setModalData] = useState();
  const [flightModalInfoOpen, setFlightModalInfoOpen] = useState(false);
  const [beaconModalInfoOpen, setBeaconModalInfoOpen] = useState(false);

  const onLoad = useCallback((map) => setMap(map), []);

  const showInfoWindow = (index, value) => {
    setInfoWindowOpen({ ...infoWindowOpen, [index]: value });
  };

  const showMarker = (index, value) => {
    setMarkerVisible({ ...markerVisible, [index]: value });
  };

  const showAllMarkers = (value) => {
    let temp = {}
    data.Items?.forEach((row) => {
      temp[row.beaconID] = value;
    });
    setMarkerVisible(temp)
    setAllMarkersVisible(value)
  }

  const openModal = (row) => {
    setOpenBeaconInfo(true);
    setModalData(row);
  }
  const closeModal = (row) => {
    setOpenBeaconInfo(false)
  }

  useEffect(() => {
    docClient.scan(params, function (err, data) {
      if (err) {
        //console.log(err);
      } else {
        setData(data);
        let temp = {}
        data.Items?.forEach((row) => {
          temp[row.beaconID] = allMarkersVisible;
        });
        setMarkerVisible(temp)
      }
    });
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      data.Items?.map((marker) => {
        bounds?.extend({
          lat: Number(marker.beaconLatitude),
          lng: Number(marker.beaconLongitude),
        });
      });
      map.fitBounds(bounds, {top: 40, right: 40, bottom: 40, left: 40});
    }
  }, [map, data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox display="flex">
        <Card id="delete-account" sx={{ height: "100%" }}>
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <VuiButton
              variant={allMarkersVisible ? "gradient" : "outlined"}
              color="light"
              onClick={() => showAllMarkers(!allMarkersVisible)}
              id="allIncidents"
            >
              All Incidents
            </VuiButton>
            {data?.Items &&
              data.Items?.map((row) => {
                return (
                  <VuiButton
                    variant={markerVisible[row.beaconID] ? "gradient" : "outlined"}
                    color="dark"
                    onClick={() => showMarker(row.beaconID, !markerVisible[row.beaconID])}
                    sx={{marginTop: "1rem"}}
                    id=""
                  >
                    <WorkOutlineIcon />&nbsp;
                    {row.beaconID}&nbsp;
                    <FlightTakeoffIcon />&nbsp;
                    {row.departureFlightId}
                  </VuiButton>
                );
              })}
          </VuiBox>
        </Card>
        <LoadScript googleMapsApiKey="AIzaSyDWDxuWdV1OGkHucKKjW0H3PlE494W11gY">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            mapTypeId="satellite"
          >
            {data?.Items &&
              data.Items?.map((row, index) => {
                const plot = {
                  lat: Number(row.beaconLatitude),
                  lng: Number(row.beaconLongitude),
                };
                return (
                  <>
                    {markerVisible[row.beaconID] && (
                        <Marker
                          position={plot}
                          onClick={() => showInfoWindow(index, true)}
                          key={index}
                        >
                          {infoWindowOpen[index] && (
                            <InfoWindow
                              key={index}
                              onCloseClick={() => showInfoWindow(index, false)}
                            >
                              <p>BeaconID: {row.beaconID} <br/>
                              <VuiButton sx={{padding: 0}} variant="text" color="info" onClick={()=>openModal(row)}>view details</VuiButton>
                              </p>
                            </InfoWindow>
                          )}
                        </Marker>
                      )}
                  </>
                );
              })}
            <></>
          </GoogleMap>
        </LoadScript>
        <Modal open={openBeaconInfo} onClose={()=>closeModal()}>
        <VuiBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }} sx={styleModal}>
          {/* <LoadScript> */}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            mapTypeId="satellite"
          >
            {modalData &&
            <>
              <Marker
                id="beacon-modal-id"
                position={{
                  lat: Number(modalData.beaconLatitude),
                  lng: Number(modalData.beaconLongitude),
                }}
                onClick={()=>setBeaconModalInfoOpen(true)}>
                  {beaconModalInfoOpen &&
                    <InfoWindow><div>beacon</div></InfoWindow>
                  }
              </Marker>
              <Marker
                id="flight-modal-id"
                icon={Airplane}
                position={{
                  lat: Number(modalData.gateLatitude),
                  lng: Number(modalData.gateLongitude),
                }}
                onClick={()=>setFlightModalInfoOpen(true)}>
                  {flightModalInfoOpen &&
                    <InfoWindow><div>hello</div></InfoWindow>
                  }
                  <Circle
                    center={{
                      lat: Number(modalData.gateLatitude),
                      lng: Number(modalData.gateLongitude),
                    }}
                    // required
                    options={{
                      strokeColor: 'black',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: 'green',
                      fillOpacity: 0.35,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      visible: true,
                      radius: Number(modalData.boundingRadius),
                      zIndex: 1
                    }}
                  />
              </Marker>
            </>
            }       
          </GoogleMap>
          {/* </LoadScript> */}
        </VuiBox>
        </Modal>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Maps;
