/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from "react";

import borders from "assets/theme/base/borders";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiSelect from "components/VuiSelect";
import VuiAvatar from "components/VuiAvatar";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import GradientBorder from "examples/GradientBorder";

import DefaultCell from "layouts/ecommerce/orders/monitoring/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/monitoring/components/StatusCell";

import Papa from "papaparse";

// Data
import dataTableData from "layouts/ecommerce/orders/monitoring/data/dataTableData";
import csvFile from "assets/arrival_data/united_bag_sample.csv";
import palette from "assets/theme/base/colors";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz"

import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import avatar5 from "assets/images/avatar5.png";
import avatar6 from "assets/images/avatar6.png";
import avatar7 from "assets/images/avatar7.png";
import avatar8 from "assets/images/avatar8.png";
import avatar9 from "assets/images/avatar9.png";
import avatar10 from "assets/images/avatar10.png";

import { components } from "react-select";

import {
  get_mishandling_risk_data,
  get_bag_info,
  get_mishandling_data_given_date,
} from "../../../../graphql/get_data";

const { Option } = components;


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  backgroundColor: palette.secondary.main,
};

const styleRowModal = {
  position: "absolute",
  top: "40%",
  left: "40%",
  width: "80%",
  transform: "translate(-30%, -40%)",
  boxShadow: 24,
  p: 4,
  backgroundColor: palette.secondary.main,
};

const people = [
  {
    value: "Esthera Jackson",
    label: "Esthera Jackson",
    image: avatar1,
  },
  {
    value: "Alexa Liras",
    label: "Alexa Liras",
    image: avatar2,
  },
  {
    value: "Laurent Perrier",
    label: "Laurent Perrier",
    image: avatar3,
  },
  {
    value: "Freduardo Hill",
    label: "Freduardo Hill",
    image: avatar4,
  },
  {
    value: "Daniel Thomas",
    label: "Daniel Thomas",
    image: avatar5,
  },
  {
    value: "Mark Wilson",
    label: "Mark Wilson",
    image: avatar6,
  },
  {
    value: "Bob Smith",
    label: "Bob Smith",
    image: avatar7,
  },
  {
    value: "Eric Southwest",
    label: "Eric Southwest",
    image: avatar8,
  },
  {
    value: "Jane United",
    label: "Jane United",
    image: avatar9,
  },
  {
    value: "Henry Delta",
    label: "Henry Delta",
    image: avatar10,
  },
];

const IconOption = (props) => (
  <Option {...props}>
    <VuiBox display="flex" alignItems="center">
      <VuiBox mr={2} color="white">
        <VuiAvatar
          src={props.data.image}
          name={props.data.name}
          variant="rounded"
          size="sm"
        />
      </VuiBox>
      <VuiTypography color="white" fontSize="1rem">{props.data.label}</VuiTypography>
    </VuiBox>
  </Option>
);

function Monitoring() {
  const [menu, setMenu] = useState(null);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [openRowModal, setOpenRowModal] = useState(false);
  const [assignmentOptions, setAssignmentOptions] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [row, setRow] = useState();
  const [bagData, setbagData] = useState();
  const [newTableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("Remove Filter");

  const [date, setDate] = useState(utcToZonedTime(new Date(), "Europe/Amsterdam"));

  const [data, setData] = useState();

  const openMenu = (event) => setMenu(event.currentTarget);

  const closeMenu = (event) => {
    setFilter(event.target.innerHTML);
    setMenu(null);
  };

  const LOCAL_STORAGE_KEY = "bags_assigned";
  function addHandlerToBag(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    set.push(bag_id);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(set));
  }

  function removeHandlerFromBag(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(set.filter(e => e !== bag_id)));
  }

  function checkBagHasHandler(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    return set.includes(bag_id);
  }

  const handleOpenAssignment = (row) => () => {
    // Shuffle array
    const shuffled = people.sort(() => 0.5 - Math.random());
    // // Get sub-array of first n elements after shuffled
    const randomNumber = Math.floor(Math.random() * 7) + 3;
    const selected = shuffled.slice(0, randomNumber);
    setRow(row);
    setAssignmentOptions(selected);
    setSelectedAssignment("");
    setOpenAssignmentModal(true);
  };

  const handleCloseAssignment = () => {
    //console.log(row);
    if (selectedAssignment !== "") {
      row.values["assignment"] = "Assigned";
      addHandlerToBag(row.values["bag_id"]);
    }
    setOpenAssignmentModal(false);
  };

  async function fetchAndUpdateBagInfo(row) {
    const bagData = await get_bag_info(row?.values["bag_id"]);
    bagData["priority"] = row?.values["priority"];
    bagData["emissions"] = row?.values["emissions"];
    setRow(row);
    setbagData(bagData);
  }

  const handleOpenRow = (row) => () => {
    fetchAndUpdateBagInfo(row);
    setOpenRowModal(true);
  };

  const handleCloseRow = () => {
    row.values["assignment"] = "Assigned";
    addHandlerToBag(bagData["bag_id"]);
    setOpenRowModal(false);
  };

  async function fetchAndUpdateData() {
    const columnNameMap = new Map([
      ["bag_id", "Bag ID"],
      ["departure_flight_number", "Departure Flight No."],
      ["departure_gate", "Gate"],
      ["estimated_departure", "Departure Time"],
      ["priority", "Priority"],
    ]);

    const apiData = await get_mishandling_data_given_date(1000, date);
    let columns = [],
      rows = [];

    columns.push(
      {
        Header: "BAG ID",
        accessor: "bag_id",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "FLIGHT",
        accessor: "departure_flight_number",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "GATE",
        accessor: "departure_gate",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "DEP. TIME",
        accessor: "estimated_departure",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "PRIORITY",
        accessor: "priority",
        Cell: ({ value }) => {
          switch (value) {
            case "High":
              return <StatusCell color="error" status="High" />;
            case "Medium":
              return <StatusCell color="warning" status="Medium" />;
            case "Low":
              return <StatusCell color="white" status="Low" />;
            default:
              return <StatusCell color="white" status="N/A" />;
          }
        },
      },
    );
    columns.push(
      {
        Header: "EMISSIONS",
        accessor: "emissions",
        Cell: ({ value }) => {
          return (
            <>
              {value} kg CO<sub>2</sub>
              <EnergySavingsLeafIcon color="success" />
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "assignment",
        Cell: ({ value, row }) => {
          if (value === "Assigned") {
            return (
              <VuiButton size="small" 
                  color="white" 
                  variant="outlined" 
                  sx={{ backgroundColor: "rgba(39, 149, 0, 1)" }}
              >
                {value}
              </VuiButton>
            );
          } else {
            return (
              <VuiButton
                size="small"
                color="white"
                variant="outlined"
                onClick={handleOpenAssignment(row)}
              >
                {value}
              </VuiButton>
            );
          }
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row }) => (
          <VuiButton
            size="small"
            color="white"
            className="take-action"
            variant="outlined"
            onClick={handleOpenRow(row)}
            sx={{
              border: "none",
              backgroundColor: "rgba(240, 112, 72, 0.85)",
              "&:hover": {
                border: "none",
              },
            }}
          >
            TAKE ACTION
          </VuiButton>
        ),
      }
    );

    rows = apiData.map((row) => {
      row["assignment"] = checkBagHasHandler(row["bag_id"]) ? "Assigned" : "Unassigned";

      return row;
    });

    setTableData({ columns: columns, rows: rows });
  }

  useEffect(() => {
    fetchAndUpdateData();
  }, [filter, date]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Priority: High</MenuItem>
      <MenuItem onClick={closeMenu}>Priority: Medium</MenuItem>
      <MenuItem onClick={closeMenu}>Priority: Low</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <VuiTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </VuiTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox my={3} display="flex">
        <Card
          height="100%"
          sx={{ width: "12%", marginRight: "3rem", outline: "1px solid white", paddingTop: "2rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", paddingBottom: "1rem" }}
        >
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            flexDirection="column"
            mb = {5}
          >

            <VuiButton
              mt={5} 
              paddingBottom="0.5rem"
              color="light" 
              fontSize="3rem"
              textAlign="left"
              textTransform="none"
              paddingLeft="0"
              sx={{ width: "100%", borderRadius: "25px", textTransform: "none", height: "50%", fontSize: "0.8rem", paddingLeft: "-2.5rem", mb: "3rem" }}
            >
              All Incidents
            </VuiButton>
            {/* <Divider light width="100%" /> */}
            {/* <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="bold"
              pb={1}
            >
              {" "}
              Filter by Terminal
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal A{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal B{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal C{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal D{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal E{" "}
            </VuiTypography> */}

            <Divider light width="100%" />
            {/* <Divider className={classes.shinyDivider} /> */}
            <VuiTypography
              // font size slightly bigger than small
              fontSize="0.85rem"
              color="white"
              fontWeight="bold"
              mt={-1}
              pb={3}
            >
              {" "}
              Filter by Routes{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              International{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={3}
            >
              {" "}
              Domestic{" "}
            </VuiTypography>
            <Divider light width="100%" />

            <VuiTypography
              fontSize="0.85rem"
              color="white"
              fontWeight="bold"
              mt={-1}
              pb={3}
            >
              {" "}
              Filter by Priority
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              High{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Medium{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={3}
            >
              {" "}
              Low{" "}
            </VuiTypography>
            <Divider light width="100%" />

            <VuiTypography
              fontSize="0.85rem"
              color="white"
              fontWeight="bold"
              mt={-1}
              pb={3}
            >
              {" "}
              Filter by Assignment{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Unassigned{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Assigned{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={25}
            >
              {" "}
              Not Applicable{" "}
            </VuiTypography>
          </VuiBox>
        </Card>

        <Card sx={{ width: "80%", backgroundColor: "rgba(9, 13, 42, 1.0)" }}>
          {newTableData.length !== 0 ? (
            <DataTable table={newTableData} entriesPerPage={false} dateData={
              {date: date, setDate: setDate, startDate: undefined, endDate: undefined}
            } canSearch />
          ) : (
            ``
          )}
          <Modal
            open={openAssignmentModal}
            onClose={handleCloseAssignment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: "blur(2px)" }}
          >
            <GradientBorder
              borderRadius={borders.borderRadius.form}
              minWidth="100%"
              maxWidth="100%"
            >
              <VuiBox
                component="form"
                role="form"
                borderRadius="inherit"
                p="45px"
                sx={style}
              >
                <VuiBox
                  mb={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                 >
                  <VuiTypography color="white" fontWeight="medium">
                    Assign to...
                  </VuiTypography>
                </VuiBox>
                <VuiBox>
                  <VuiSelect
                    placeholder="Assign a person..."
                    options={assignmentOptions}
                    components={{ Option: IconOption }}
                    onChange={(event) => setSelectedAssignment(event.value)}
                  />
                </VuiBox>
                <VuiBox mt={4} mb={1} textAlign="center">
                  <VuiButton
                    variant="gradient"
                    color="dark"
                    circular
                    onClick={handleCloseAssignment}
                  >
                    Assign
                  </VuiButton>
                </VuiBox>
                <VuiBox mt={4} mb={1} textAlign="center"></VuiBox>
              </VuiBox>
            </GradientBorder>
          </Modal>
          <Modal
            open={openRowModal}
            onClose={handleCloseRow}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: "blur(2px)" }}
          >
            <VuiBox
              borderRadius="lg"
              shadow="lg"
              mt={{ xs: 4, md: 10 }}
              mb={{ xs: 4, md: 8 }}
              sx={styleRowModal}
            >
              {/* <Grid container justifyContent="center" >
                <Grid item sm={12} md={8} sx={{ background: "#060B28" }}> */}
              {/* <Card> */}
              <VuiBox
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <VuiTypography color="white" pr={1}>
                  Incident Details{" "}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  pr={1}
                  sx={{ color: "rgb(90 140 224)"}}
                >
                  BagID
                </VuiTypography>
                <VuiTypography variant="caption" color="warning">
                  {bagData?.["bag_id"]}
                </VuiTypography>
              </VuiBox>
              {/* <VuiBox sx={{
                display: "flex", justifyContent: "space-between", flexWrap: "wrap",
                paddingTop: "1rem"
              }}> */}
              <Grid container spacing={1}>
                <Grid container item spacing={1}>
                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      ARR. FLIGHT
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["arrival_flight_number"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      DEP. FLIGHT
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["departure_flight_number"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      SCH. TRANSFER
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["actual_transfer_time"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      PNR
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["pnr"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      PRIORITY
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color={
                        bagData?.["priority"] === "High"
                          ? "error"
                          : bagData?.["priority"] === "Medium"
                          ? "warning"
                          : "white"
                      }
                      fontWeight="regular"
                    >
                      {bagData?.["priority"]}
                    </VuiTypography>
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      ARR. TIME
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["estimated_arrival"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      DEP. TIME
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["estimated_departure"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      EST. TRANSFER
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["estimated_transfer_time"]}
                    </VuiTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <VuiTypography
                      variant="caption"
                      fontWeight="regular"
                      pr={1}
                      sx={{ color: "rgb(90 140 224)" }}
                    >
                      EMISSIONS
                    </VuiTypography>
                    <VuiTypography
                      variant="caption"
                      color="white"
                      fontWeight="regular"
                    >
                      {bagData?.["emissions"]} kg CO<sub>2</sub>
              <EnergySavingsLeafIcon color="success" />
                    </VuiTypography>
                  </Grid>
                </Grid>
              </Grid>
              {/* </VuiBox> */}
              <Divider light />
              <VuiTypography color="white">Bag Information</VuiTypography>
              <VuiBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  paddingTop: "1rem",
                }}
              >
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  BAG SIZE
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_size"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  WEIGHT
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_weight"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  COLOR
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_color"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  TYPE
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_type"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  LOADING POSITION
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["loading_position"]}
                </VuiTypography>
              </VuiBox>
              <Divider light />
              <VuiTypography color="white">Resolution Center</VuiTypography>
              <VuiBox>
                <VuiBox>
                  <VuiTypography
                    variant="caption"
                    fontWeight="regular"
                    pr={1}
                    sx={{ color: "rgb(90 140 224)" }}
                  >
                    RESOLUTION CODE
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {bagData?.["resolution_code"]}
                  </VuiTypography>
                </VuiBox>
                <VuiBox>
                  <VuiTypography
                    variant="caption"
                    fontWeight="regular"
                    pr={1}
                    sx={{ color: "rgb(90 140 224)" }}
                  >
                    RESOLUTION NAME
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {bagData?.["resolution_name"]}
                  </VuiTypography>
                </VuiBox>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  INSTRUCTIONS
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["resolution_instructions"]}
                </VuiTypography>
              </VuiBox>

              <VuiBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "1rem",
                }}
              >
                <VuiButton
                  variant="outlined"
                  sx={{ mr: 1 }}
                  onClick={handleCloseRow}
                >
                  Cancel
                </VuiButton>
                <VuiButton color="success" onClick={handleCloseRow}>
                  Recover Baggage
                </VuiButton>
              </VuiBox>
            </VuiBox>
          </Modal>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Monitoring;
