/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { Card, Grid } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import MixedChart from "examples/Charts/MixedChart";
import { mixedChartData, mixedChartOptions } from "../../data/risksChartData";
import { lineChartDataDashboard, lineChartOptionsDashboard } from "../../data/lineChart";

import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import { addDays }  from 'date-fns';

const cube = (
  <svg style={{paddingRight: "5px"}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25px" height="25px" version="1.1" viewBox="0 0 700 700">
 <g>
  <path d="m345.52 283.92v248.08l-215.04-123.76v-248.08zm8.9609 0v248.08l214.48-123.76v-248.08zm210-132.16-214.48-123.76-214.48 123.76 214.48 124.32z"/>
 </g>
</svg>
);

const SalesOverview = ({date, prevented, prediction}) => {
  if (prevented != null) { mixedChartData[0].data = prevented; }
  if (prediction != null) { mixedChartData[1].data = prediction; }
  
  if (date != null) { 
    let dates = [...Array(12).keys()].reverse()
                .map((x) => addDays(date, -x-1))
                .map(x => format(x, "MM/dd"));
    mixedChartOptions.xaxis.categories = dates; 
  }

  return (
    <MixedChart
      title="Mishandling Risks"
      description={<>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div><span style={{color: 'rgba(183, 29, 255, 1)'}}>(+52) more </span><span> today</span></div>
        <div style={{display: "flex"}}>
          <span style={{
            borderRadius: "50%", 
            width: "10px", 
            height: "10px", 
            backgroundColor: "#10B981", //"#10B981"
            display: "block",
            marginRight: "5px",
            alignSelf: "center"
            }}></span>
          <span>Prevented</span>
        </div>
        </div>
        {/* <VuiButton size="small" color='info' sx={{    
          position: 'absolute',
          top: '32px',
          right: '22px',
          padding: '0px 8px',
          textTransform: 'none', // To prevent the text from being all capitals
          '& .MuiButton-label': {
            color: 'white', // To set the text color to white
            fontSize: '2rem', // Change this value to the desired font size
          },
          }}
        > 
          {/* <span style={{ color: 'white' }}>{cube}</span>   */}
          {/* See All 
        </VuiButton> */} 
        </>
        }
      data={mixedChartData}
      options={mixedChartOptions}
    />
  );
};

export default SalesOverview;
