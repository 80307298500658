/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useMemo, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; 
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import DataTable from "../DataTable";
import Link from "@mui/material/Link";
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { borders as borders_mui } from '@mui/system';



import DefaultCell from "layouts/ecommerce/orders/monitoring/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/monitoring/components/StatusCell";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import palette from "assets/theme/base/colors";

import { get_mishandling_risk_data, get_risky_bags_given_flight_number } from "../../../graphql/get_data";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz"
import { addDays }  from 'date-fns'

const styleRowModal = {
  position: "absolute",
  top: "35%",
  left: "39.5%",
  width: "80%",
  transform: "translate(-30%, -40%)",
  boxShadow: 24,
  p: 4,
  backgroundColor: palette.secondary.main,
};

function Table({ columns, rows, title, date, setDate }) {
  const { light, grey } = colors;
  const { size, fontWeightMedium } = typography;
  const { borderWidth } = borders;
  const [openRowModal, setOpenRowModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalData, setModalData] = useState([]);
  const [sortRows, setSortedRows] = useState();

  const ams_now = utcToZonedTime(new Date(), "Europe/Amsterdam");
  const ams_plus4days = utcToZonedTime(addDays(new Date(), 4), "Europe/Amsterdam");

  const renderColumns = (columns) => {
    const col = columns.map(({ name, align, width, display }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 0;
        pr = 3;
        align = "left";
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <VuiBox
        key={uuidv4()}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.xs}
          fontWeight={fontWeightMedium}
          color="text"
          opacity={0.8}
          borderBottom={`${borderWidth[1]} solid ${grey[600]}`}
          className={`top-risks-${name}`}
          onClick={()=>{setSortedRows(name)}}
        >
          { display?.toUpperCase() || name.toUpperCase()}
        </VuiBox>
      );
    });
    return col;
  };

  async function fetchAndUpdateData(flight_number, date) {
    const apiData = await get_risky_bags_given_flight_number(flight_number, date);
    let columns = [],
      rows = [];

    columns.push(
      {
        Header: "BAG ID",
        accessor: "bag_id",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "FLIGHT",
        accessor: "departure_flight_number",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "GATE",
        accessor: "departure_gate",
        Cell: ({ value }) => {
          return (
            <>
              {value}
            </>
          );
        },
      },
    );
    columns.push(
      {
        Header: "PRIORITY",
        accessor: "priority",
        Cell: ({ value }) => {
          switch (value) {
            case "High":
              return <StatusCell color="error" status="High" />;
            case "Medium":
              return <StatusCell color="warning" status="Medium" />;
            case "Low":
              return <StatusCell color="white" status="Low" />;
            default:
              return <StatusCell color="white" status="N/A" />;
          }
        },
      },
    );

    columns.push(
      {
        Header: "EMISSIONS",
        accessor: "emissions",
        Cell: ({ value }) => {
          return (
            <>
              {value} kg CO<sub>2</sub>
              <EnergySavingsLeafIcon color="success" />
            </>
          );
        },
      },
    );
    
    rows = apiData.map((row) => {
      row["assignment"] = "Unassigned";
      return row;
    });

    setModalData({ columns: columns, rows: rows });
  }

  const clickedRows = (row) => {
    setRowData(row);
    fetchAndUpdateData(row["arrival_flight_number_full"], row["date"]);
    setOpenRowModal(true);
  };

  const handleCloseRow = () => {
    setOpenRowModal(false);
  };



  const renderRows = (rows, sortRows) => {
    // sortRows is the column name with which the rows are sorted in descending order
    rows.sort((a, b) => b[sortRows] - a[sortRows] );
    // map function iterates over each row
    const r = rows.map((row, key) => {
      const rowKey = `row-${key}`;

      const tableRow = columns.map(({ name, align }) => {
        let template;
        if (name === "arrival_flight_number_full"){
          align = "left";
        }else{
          align = "center";
        }

        if (Array.isArray(row[name])) {
          template = (
            <VuiBox
              key={uuidv4()}
              component="td"
              py={1}
              borderBottom={
                row.hasBorder ? `${borderWidth[1]} solid ${grey[600]}` : null
              }
            >
              <VuiBox display="flex" alignItems={align} py={0.5}>
                <VuiBox mr={2}>
                  <VuiAvatar
                                  key={uuidv4()}

                    src={row[name][0]}
                    name={row[name][1]}
                    variant="rounded"
                    size="sm"
                  />
                </VuiBox>
                <VuiTypography
                                key={uuidv4()}

                  variant="button"
                  color="white"
                  fontWeight="medium"
                  sx={{ width: "max-content" }}
                >
                  {row[name][1]}
                </VuiTypography>
              </VuiBox>
            </VuiBox>
          );
        } else {
          if (name === "arrival_flight_number_full"){
            align = "left";

          }else{
            align = "center";
          }
          template = (
            <>
              <VuiBox
                key={uuidv4()}
                component="td"
                // pl={align === "left" ? 0 : 1}
                // pr={align === "left" ? 0 : 1}
                pb={1}
                pt={1}
                textAlign={align}
                borderBottom={
                  row.hasBorder ? `${borderWidth[1]} solid ${grey[600]}` : null
                }
                onClick={() => clickedRows(row)}
              >
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  sx={{ display: "inline-block", width: "max-content" }}
                >
                  {row[name]}{name === "emissions" && (
                    <>
                    {" "} kg CO<sub>2</sub>
                  <EnergySavingsLeafIcon color="success"/>
                  </>
                  )}
                </VuiTypography>
              </VuiBox>
            </>
          );
        }

        return template;
      });

      return <TableRow key={rowKey}>{tableRow}</TableRow>;
    });
    return r;
  };

  const renderedColumns = useMemo(() => renderColumns(columns), [columns]);
  const renderedRows = useMemo(() => renderRows(rows, sortRows), [rows, sortRows]);


  return (
      <Card
        sx={{
          backgroundColor: "rgba(9, 13, 42, 1.0)",
          ...({ breakpoints }) => ({
            [breakpoints.down("xl")]: {
              overflowX: "scroll",
            },
          }),
        }}
        >
        <VuiBox mb={7} >
          <VuiTypography 
            variant="lg" 
            color="white" 
            fontWeight="800" 
            mb="2rem"  // Increase the font size here
            sx={{
              position: "absolute",
              top: "1rem",
              left: "2rem",
              fontSize: "1.3rem",
            }}
          >
            {title}
          </VuiTypography>
          <VuiBox align="right">
            {(date !== undefined) ? 
            <ThemeProvider theme={createTheme({palette: {mode: 'dark'}})}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  onChange={setDate} 
                  value={date} 
                  minDate={ams_now}
                  maxDate={ams_plus4days}
                  sx={{'.MuiInputBase-root': {borderRadius: "1.25rem"}}}
                />
              </LocalizationProvider>
            </ThemeProvider>
             : ""}
          </VuiBox>
        </VuiBox>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderedColumns}</TableRow>
          </VuiBox>
          <TableBody>{renderedRows}</TableBody>
        </MuiTable>

        <Modal
          open={openRowModal}
          onClose={handleCloseRow}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ backdropFilter: "blur(2px)" }}
        >
          <VuiBox
            borderRadius="lg"
            shadow="lg"
            mt={{ xs: 4, md: 10 }}
            mb={{ xs: 4, md: 8 }}
            sx={styleRowModal}
           >
            <VuiBox
              sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
             >
              <VuiTypography variant='lg' color="white" fontWeight="800" pr={1} sx={{fontSize:"1.5rem"}}>
                Route Information -{" "}
              </VuiTypography>
              <VuiTypography color="warning" pr={1}>
                {rowData?.["arrival_flight_number"]}
              </VuiTypography>
            </VuiBox>
            <VuiBox sx={{ display: "flex", justifyContent: "space-between" }} pt={1}>
              <VuiBox sx={{ display: "flex", flexDirection: "column" }}>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  ARRIVAL:{" "} <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  pt={1}
                  color="white"
                >
                  {rowData?.["date"]} {rowData?.["estimated_arrival"]}
                </VuiTypography>
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  pt={1}
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  GATE:{" "}  <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  color="white"
                >
                  {rowData?.["gate"]}
                </VuiTypography>
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  pt={1}
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  EQUIPMENT:{" "} 
                  <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  pr={1}
                  color="white"
                >
                  {rowData?.["aircraft_equipment"]}
                </VuiTypography>
                </VuiTypography>
              </VuiBox>
              <VuiButton width="20rem" height="5rem" color="info" href="/ecommerce/orders/monitoring#/monitoring" component={Link}
                sx={{ fontSize: '1rem', 'mb': "1rem", 'mt': "-2rem", 'mr': "3rem" }}
                >
                Open Monitoring
              </VuiButton>
            </VuiBox>

            <VuiBox sx={{padding: "1rem 2rem 0", fontSize:"2rem"}}>
              {modalData.length !== 0 ? (
                <DataTable
                  table={modalData}
                  showTotalEntries={false}
                  entriesPerPage={false}
                />
              ) : (
                ``
              )}
            </VuiBox>
          </VuiBox>
        </Modal>
      </Card>
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
