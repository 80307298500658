/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from "react";

import borders from "assets/theme/base/borders";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiSelect from "components/VuiSelect";
import VuiAvatar from "components/VuiAvatar";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import GradientBorder from "examples/GradientBorder";

import DefaultCell from "layouts/ecommerce/orders/monitoring/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/monitoring/components/StatusCell";

import Papa from "papaparse";

// Data
import dataTableData from "layouts/ecommerce/orders/monitoring/data/dataTableData";
import csvFile from "assets/arrival_data/united_bag_sample.csv";
import palette from "assets/theme/base/colors";

import bag0 from "assets/images/bag-images/0.png";
import luggage from "assets/images/bag-images/luggage.png";
import map from "assets/images/bag-images/map.png";

import { components } from "react-select";
import TimelineItem from "examples/Timeline/TimelineItem";
import TimelineList from "examples/Timeline/TimelineList";

import {
  get_mishandling_risk_data,
  get_bag_info,
} from "../../../../graphql/get_data";

const { Option } = components;

const images = {
  0: bag0,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  backgroundColor: palette.secondary.main,
};

// This should work
const styleRowModal = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",//"auto",
  height: "auto",
  maxHeight: "78%",
  boxShadow: 24,
  p: 0, // p: 4,
  borderRadius: "37.08px",
  backgroundColor: palette.secondary.main,
};

const styleRowImageModal = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",//"auto",
  height: "auto",
  objectFit: "contain",
  boxShadow: 24,
  p: 0, // p: 4,
  borderRadius: "37.08px",
  backgroundColor: palette.secondary.main,
};

const IconOption = (props) => (
  <Option {...props}>
    <VuiBox display="flex" alignItems="center">
      <VuiBox mr={2}>
        <VuiAvatar
          src={props.data.image}
          name={props.data.name}
          variant="rounded"
          size="sm"
        />
      </VuiBox>
      {props.data.label}
    </VuiBox>
  </Option>
);

function Tracking() {
  const [menu, setMenu] = useState(null);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [openRowModal, setOpenRowModal] = useState(false);
  const [assignmentOptions, setAssignmentOptions] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [row, setRow] = useState();
  const [bagData, setbagData] = useState();
  const [newTableData, setTableData] = useState([]);
  const [filter, setFilter] = useState("Remove Filter");
  const [rowClicked, setRowClicked] = useState(0);

  const [data, setData] = useState();

  const openMenu = (event) => setMenu(event.currentTarget);

  const closeMenu = (event) => {
    setFilter(event.target.innerHTML);
    setMenu(null);
  };

  const LOCAL_STORAGE_KEY = "bags_assigned";
  function addHandlerToBag(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    set.push(bag_id);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(set));
  }

  function removeHandlerFromBag(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(set.filter((e) => e !== bag_id))
    );
  }

  function checkBagHasHandler(bag_id) {
    const storedset = localStorage.getItem(LOCAL_STORAGE_KEY);
    const set = storedset ? JSON.parse(storedset) : [];
    //console.log(set);
    return set.includes(bag_id);
  }

  const handleCloseAssignment = () => {
    //console.log(row);
    if (selectedAssignment !== "") {
      row.values["assignment"] = "Assigned";
      addHandlerToBag(row.values["bag_id"]);
    }
    setOpenAssignmentModal(false);
  };

  async function fetchAndUpdateBagInfo(row) {
    const bagData = await get_bag_info(row?.values["bag_id"]);
    bagData["priority"] = row?.values["priority"];
    bagData["emissions"] = Math.floor(Math.random() * 4) + 1;
    setRow(row);
    setbagData(bagData);
  }

  const handleOpenRow = (row) => () => {
    fetchAndUpdateBagInfo(row);
    setRowClicked(row.id);
    setOpenRowModal(true);
  };

  const handleCloseRow = () => {
    row.values["assignment"] = "Assigned";
    addHandlerToBag(bagData["bag_id"]);
    setOpenRowModal(false);
  };

  async function fetchAndUpdateData() {
    const columnNameMap = new Map([
      ["bag_id", "BAG ID"],
      ["arrival_flight_number", "ARRIVAL FLIGHT"],
      ["arrival_gate", "GATE"],
      // ["transfer_time", "Transfer Time"],
    ]);

    const apiData = await get_mishandling_risk_data(50);
    //console.log(apiData);
    let columns = [],
      rows = [];

    columnNameMap.forEach((display_name, key_name) => {
      columns.push({
        Header: display_name,
        accessor: key_name,
      });
    });

    columns.push(
      {
        Header: "TRANSFER TIME",
        accessor: "transfer_time",
        Cell: ({ value }) => value,
      },
      {
        Header: "DESTINATION",
        accessor: "destination",
        Cell: ({ value }) => value,
      },
      {
        Header: "",
        accessor: "track",
        Cell: ({ row }) => (
          <VuiButton
            size="small"
            color="white"
            variant="outlined"
            onClick={handleOpenRow(row)}
            sx={{
              border: "none",
              backgroundColor: "rgba(240, 112, 72, 0.85)",
              "&:hover": {
                border: "none",
              },
            }}
          >
            TRACK
          </VuiButton>
        ),
      }
    );

    rows = apiData.map((row) => {
      const destinations = [
        "LHR",
        "BCN",
        "ARN",
        "MAD",
        "GLA",
        "MXP",
        "FRA",
        "BRU",
        "ICN",
        "LAX",
        "ATL",
        "SLC",
        "LHR",
        "BER",
        "FCU",
      ];
      row["destination"] =
        destinations[Math.floor(Math.random() * destinations.length)];
      return row;
    });

    setTableData({ columns: columns, rows: rows });
  }

  useEffect(() => {
    fetchAndUpdateData();
  }, [filter]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Priority: High</MenuItem>
      <MenuItem onClick={closeMenu}>Priority: Medium</MenuItem>
      <MenuItem onClick={closeMenu}>Priority: Low</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <VuiTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </VuiTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox my={3} display="flex">
        <Card
          height="100%"
          sx={{ width: "12%", marginRight: "3rem", outline: "1px solid white", paddingTop: "2rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", paddingBottom: "1rem" }}
        >
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            flexDirection="column"
            mb={5}
          >
            <VuiButton
              mt={5}
              paddingBottom="0.5rem"
              color="light"
              fontSize="3rem"
              textAlign="left"
              textTransform="none"
              paddingLeft="0"
              sx={{
                width: "100%",
                borderRadius: "25px",
                textTransform: "none",
                height: "50%",
                fontSize: "0.8rem",
                paddingLeft: "-2.5rem",
                mb: "3rem",
              }}
            >
              All Incidents
            </VuiButton>
            {/* <Divider light width="100%" /> */}
            {/* <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="bold"
              pb={1}
            >
              {" "}
              Filter by Terminal
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal A{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal B{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal C{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal D{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              Terminal E{" "}
            </VuiTypography> */}

            <Divider light width="100%" />
            <VuiTypography
              fontSize="0.85rem"
              color="white"
              fontWeight="bold"
              mt={-1}
              pb={3}
            >
              {" "}
              Filter by Routes{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={1}
            >
              {" "}
              International{" "}
            </VuiTypography>
            <VuiTypography
              fontSize="small"
              color="light"
              fontWeight="light"
              pb={72}
            >
              {" "}
              Domestic{" "}
            </VuiTypography>
          </VuiBox>
        </Card>
        <Card sx={{ width: "80%", backgroundColor: "rgba(9, 13, 42, 1.0)" }}>
          {newTableData.length !== 0 ? (
            <DataTable table={newTableData} entriesPerPage={false} canSearch />
          ) : (
            ``
          )}
          <Modal
            open={openAssignmentModal}
            onClose={handleCloseAssignment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: "blur(2px)" }}
          >
            <GradientBorder
              borderRadius={borders.borderRadius.form}
              minWidth="100%"
              maxWidth="100%"
            >
              <VuiBox
                component="form"
                role="form"
                borderRadius="inherit"
                p="45px"
                sx={style}
              >
                <VuiBox
                  mb={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <VuiTypography color="white" fontWeight="medium">
                    Assign to...
                  </VuiTypography>
                </VuiBox>
                <VuiBox>
                  <VuiSelect
                    placeholder="Assign a person..."
                    options={assignmentOptions}
                    components={{ Option: IconOption }}
                    onChange={(event) => setSelectedAssignment(event.value)}
                  />
                </VuiBox>
                <VuiBox mt={4} mb={1} textAlign="center">
                  <VuiButton
                    variant="gradient"
                    color="dark"
                    circular
                    onClick={handleCloseAssignment}
                  >
                    Assign
                  </VuiButton>
                </VuiBox>
                <VuiBox mt={4} mb={1} textAlign="center"></VuiBox>
              </VuiBox>
            </GradientBorder>
          </Modal>
          <Modal
            open={openRowModal}
            onClose={handleCloseRow}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ backdropFilter: "blur(2px)" }}
          >
            <VuiBox
              // borderRadius="lg"
              shadow="lg"
              // mt={{ xs: 4, md: 10 }}
              // mb={{ xs: 4, md: 8 }}
              sx={styleRowImageModal} component = "img" src={images[rowClicked] || bag0}
            >
                              {/* <VuiTypography color="white" pr={1}>
                  Baggage tracking
                </VuiTypography>
              <VuiBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  paddingTop: "1rem",
                }}
              >
                <VuiBox
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >

                <VuiTypography
                  variant="caption"
                  pr={1}
                  sx={{ color: "rgb(90 140 224)"}}
                >
                  BagID
                </VuiTypography>
                <VuiTypography variant="caption" color="warning">
                  {bagData?.["bag_id"]}
                </VuiTypography>
              </VuiBox>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  BAG SIZE
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_size"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  WEIGHT
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_weight"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  COLOR
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_color"]}
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  fontWeight="regular"
                  sx={{ color: "rgb(90 140 224)" }}
                >
                  TYPE
                </VuiTypography>
                <VuiTypography
                  variant="caption"
                  color="white"
                  fontWeight="regular"
                >
                  {bagData?.["bag_type"]}
                </VuiTypography>
              </VuiBox>
              <Divider light />
              
              <VuiBox display="flex">
                <VuiBox width="33.3333%">
                <VuiTypography
                variant="h4"
                fontWeight="light"
                mb="30px"
                sx={{textAlign: "center"}}
              >
                STATUS
              </VuiTypography>
              <VuiBox display="flex">
              <div>
                  <TimelineItem
                    color="secondary"
                    title="BCN"
                    dateTime="18 May 5:00 AM"
                    showTime
                  />
                  <TimelineItem
                    color="secondary"
                    title="BCN"
                    dateTime="18 May 6:07 AM"
                    showTime
                  />
                  <TimelineItem
                    color="secondary"
                    title="AMS"
                    dateTime="18 May 8:43 AM"
                    showTime
                  />
                  <TimelineItem
                    color="success"
                    title="AMS"
                    dateTime="18 May 5:00 AM"
                    showTime
                  />
                </div>
                <div>
                  <TimelineItem
                    color="secondary"
                    title="Checked-in"
                    dateTime="KL 1662"
                  />
                  <TimelineItem
                    color="secondary"
                    title="Loaded"
                    dateTime="KL 1662"
                  />
                  <TimelineItem
                    color="secondary"
                    title="Unloaded"
                    dateTime="KL 1662"
                  />
                  <TimelineItem
                    color="success"
                    title="Order delivered"
                    dateTime="22 DEC 4:54 PM"
                  />
                </div>
                </VuiBox>
                </VuiBox>
                <Divider light orientation="vertical" sx={{ height: "auto" }} />
                <VuiBox width="33.333%" height="auto">
                    <VuiTypography
                    variant="h4"
                    fontWeight="light"
                    mb="30px"
                    sx={{textAlign: "center"}}
                  >
                    IDENTITY
                  </VuiTypography>
                  <VuiBox component="img" src={luggage} width="100%"></VuiBox>
                </VuiBox>
                
                <Divider light orientation="vertical" sx={{ height: "auto" }} />

                <VuiBox width="33.333%" height="auto">
                    <VuiTypography
                    variant="h4"
                    fontWeight="light"
                    mb="30px"
                    sx={{textAlign: "center"}}
                  >
                    LIVE MAP
                  </VuiTypography>
                  <VuiBox component="img" src={map} width="100%"></VuiBox>
                </VuiBox>
              </VuiBox>
              <VuiBox display="flex" sx={{    placeContent: "flex-end"
}}>
              <VuiButton
                variant="outlined"
                sx={{ mr: 1, }}
                onClick={handleCloseRow}
              >
                Close
              </VuiButton>
              </VuiBox> */}
            </VuiBox>
          </Modal>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tracking;
