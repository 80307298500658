import { mishandling_aggregate_query, 
  mishandling_risk_query,
  top_routes_at_risk_query,
  bag_info_query,
  mishandling_risk_flight_query,
  top_routes_at_risk_with_timestamp_filter_query,
  mishandling_risk_date_query
 } from './custom-graphql';
import { API } from 'aws-amplify';

import { zonedTimeToUtc, utcToZonedTime, format as tz_format } from "date-fns-tz"
import { addDays, format }  from 'date-fns'

// https://stackoverflow.com/a/23593099
function formatDate(date) {
    return format(date, 'yyyy-MM-dd');
}

function formatDateWithTZ(date, tz) {
    if (tz === undefined) {
        tz = 'Europe/Amsterdam';
    }
    // FF and V8 believes Lithuania uses "yyyy-mm-dd" with leading zeros.
    //return date.toLocaleString('lt', {timeZone: 'Europe/Amsterdam'}).substring(0,10)
    return tz_format(date, 'yyyy-MM-dd', {timeZone: tz});
}

export async function get_mishandling_aggregate_data(last_date) {
let current_date = new Date(last_date);
let fetched_data = [];

for (let i = 0; i < 10; i++) {
 let apiData = await API.graphql({
     query: mishandling_aggregate_query,
     variables: { date: formatDate(current_date) }
 });
 fetched_data.unshift(apiData.data.getYxuAetherRiskHandlingAggregateDemoModel);
 current_date.setDate(current_date.getDate() - 1);
}

return fetched_data;
}

export async function get_mishandling_risk_data(limit) {
let fetched_data = [];
for (let priority of ["High", "Medium", "Low"]) {
 let apiData = await API.graphql({
     query: mishandling_risk_query,
     variables: { prio: priority, limit: limit - fetched_data.length }
 });
 apiData.data.bagRiskByPriority.items.forEach((item) => {fetched_data.push(item);});
 if (fetched_data.length >= limit) {
     return fetched_data;
 }
}
return fetched_data;
}

export async function get_top_routes_at_risk_data(limit, tz) {
if (tz === undefined) {
    tz = 'Europe/Amsterdam';
}
//let current_date = new Date((new Date()).toLocaleString('en', {timeZone: tz}));
let current_date = new Date(2023, 5 - 1, 15);
let fetched_data = [];

while (fetched_data.length < limit) {
 let apiData = await API.graphql({
     query: top_routes_at_risk_query,
     variables: { date: formatDate(current_date), limit: limit - fetched_data.length }
 });

 apiData.data.routesByDate.items.forEach((item) => {fetched_data.push(item);});
 current_date.setDate(current_date.getDate() - 1);
}
return fetched_data;
}

export async function get_bag_info(bag_id) {
let apiData = await API.graphql({
 query: bag_info_query,
 variables: { bag_id: bag_id }
});

return apiData.data.getYxuAetherBaggageInfoDemoModel;
}

export async function get_risky_bags_given_flight_number(flight_number, date) {
    if (date === undefined) {
        date = formatDate(new Date());
    }

    let apiData = await API.graphql({
        query: mishandling_risk_flight_query,
        variables: { 
            arrival_flight_number: flight_number,
            limit: 80,
            date: date
        }
    });

    let data = apiData.data.mishandlingsByFlightNumber.items;
    return data;
}

export async function get_top_routes_at_risk_data_with_time_limit(limit, time, timeoffset, tz) {
    if (tz === undefined) {
        tz = 'Europe/Amsterdam';
    }
    if (time === undefined) {
        time = new Date();
    }
    if (timeoffset === undefined) {
        timeoffset = 60 * 60 * 6; // 6 hours
    }
    let localtime = new Date(time.toLocaleString('en', {timeZone: tz}) + " UTC");
    //console.log(localtime);
    let current_date = localtime;
    
    let fetched_data = [];
    
    let apiData = await API.graphql({
        query: top_routes_at_risk_with_timestamp_filter_query,
        variables: { 
        date: formatDateWithTZ(current_date, tz), 
        between: [localtime / 1000, (localtime / 1000) + timeoffset],
        //limit: limit - fetched_data.length 
    }
    });
    //console.log([localtime / 1000, (localtime / 1000) + timeoffset]);
    //console.log(apiData);
    apiData.data.routesByDate.items.forEach((item) => {fetched_data.push(item);});
    return fetched_data.slice(0, limit);//.sort((a, b) => b["bags_at_risk"] - a["bags_at_risk"]).slice(0, limit);
}

export async function get_two_days_top_routes_at_risk_data_with_time_limit(limit, time, timeoffset, tz) {
    if (tz === undefined) { tz = 'Europe/Amsterdam'; }
    if (time === undefined) { time = new Date(); }
    if (timeoffset === undefined) { timeoffset = 60 * 60 * 6; /* 6 hours */ }

    let localtime = new Date(time.toLocaleString('en', {timeZone: tz}) + " UTC");
    //console.log(localtime);
    let current_date = localtime;
    let next_date = new Date(current_date);
    next_date.setDate(next_date.getDate() + 1);
    
    let fetched_data = [];

    // Get day 1    
    let nextToken = null;
    do {
        let apiData = await API.graphql({
            query: top_routes_at_risk_with_timestamp_filter_query,
            variables: { 
                date: formatDateWithTZ(current_date, tz), 
                between: [localtime / 1000, (localtime / 1000) + timeoffset],
                nextToken: nextToken
            }
        });
        //console.log(apiData);
        apiData.data.routesByDate.items.forEach((item) => {fetched_data.push(item);});
        nextToken = apiData.data.routesByDate.nextToken;
    } while (nextToken != null);

    // Get day 2
    nextToken = null;
    do {
        let apiData = await API.graphql({
            query: top_routes_at_risk_with_timestamp_filter_query,
            variables: { 
                date: formatDateWithTZ(next_date, tz), 
                between: [localtime / 1000, (localtime / 1000) + timeoffset],
                nextToken: nextToken
            }
        });
        //console.log(apiData);
        apiData.data.routesByDate.items.forEach((item) => {fetched_data.push(item);});
        nextToken = apiData.data.routesByDate.nextToken;
    } while (nextToken != null);

    return fetched_data.sort((a, b) => b["bags_at_risk"] - a["bags_at_risk"]).slice(0, limit);
}

export async function get_mishandling_data_given_date(limit, date) {
    let dateStr;
    dateStr = date === undefined ? formatDateWithTZ(new Date()):formatDate(date);

    let apiData = await API.graphql({
        query: mishandling_risk_date_query,
        variables: { 
            date: dateStr,
            limit: limit 
        }
    });

    return apiData.data.bagRiskByDate.items;
}