/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";
import { VisionUIControllerProvider } from "context";
import { Auth0Provider } from "@auth0/auth0-react";


import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

// Vision UI Context Provider

ReactDOM.render(
  <Auth0Provider
  domain="dev-tjjh4rbk.us.auth0.com"
  clientId="wGEcgbJSk4898dyw7CTtAHbjL4pO6vII"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
  cacheLocation="localstorage"
  useRefreshTokens={true}
  >
    <HashRouter>
      <VisionUIControllerProvider>
        <App />
      </VisionUIControllerProvider>
    </HashRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
