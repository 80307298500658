/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiAlert from "components/VuiAlert";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";

import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/account/components/FormField";

// Vision UI Dashboard components
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import bgBasic from "assets/images/background-basic-auth.png";

import NotificationItem from "examples/Items/NotificationItem";


function SignUpAviAi() {
    const [rememberMe, setRememberMe] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const [emailError, setEmailError] = useState(false);

    const [showAuthCode, setShowAuthCode] = useState(false);

    const patterns = [
        /\S+@united.com+/,
        /\S+@southwest.com+/
    ]

    const handleChange = event => {
        if (patterns.some(pattern => pattern.test(event.target.value)) || event.target.value === '') {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }

    const handleFormSubmission = () => {
        setShowAuthCode(true)
    }

    return (
        <BasicLayout
            title="AviAi Dashboard"
            description="Welcome!"
            image={bgBasic}
            noNavBar
            maxWidth={{ xs: "90%", md: "50%" }}
        >
            {emailError ?
                <VuiAlert color="lightblue" dismissible sx={{
                    position: 'absolute',
                    top: '2rem',
                    left: '2rem'
                }}>
                    <VuiTypography variant="body2" color="white">
                        Please use an {" "}
                        <VuiTypography component="span" variant="body2" fontWeight="bold" color="white">
                            @united.com {" "}
                        </VuiTypography>
                        email address to register
                    </VuiTypography>
                </VuiAlert>
                : ''}

            <GradientBorder
                borderRadius={borders.borderRadius.form}
                minWidth={showAuthCode ? '50%' : '100%'}>
                {showAuthCode ?
                    <VuiBox
                        component="form"
                        role="form"
                        borderRadius="inherit"
                        p="45px"
                        sx={({ palette: { secondary } }) => ({
                            backgroundColor: secondary.main,
                        })}
                    >
                        <VuiBox mb={2} display="flex" alignItems="center" justifyContent="center">
                            <VuiTypography color="white" fontWeight="medium">
                                Enter AuthCode
                            </VuiTypography>
                        </VuiBox>
                        <FormField placeholder="Type here..." caption='Resend' />
                        <VuiBox mt={4} mb={1} textAlign="center">
                            <VuiButton variant="gradient" color="dark" circular onClick={handleFormSubmission}>
                                SUBMIT
                            </VuiButton>
                        </VuiBox>
                    </VuiBox>
                    :
                    <VuiBox
                        component="form"
                        role="form"
                        borderRadius="inherit"
                        p="45px"
                        sx={({ palette: { secondary } }) => ({
                            backgroundColor: secondary.main,
                        })}
                    >
                        <VuiBox mb={4} display="flex" alignItems="center" justifyContent="center">
                            <VuiTypography color="white" fontWeight="medium">
                                Register
                            </VuiTypography>
                        </VuiBox>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <FormField label="Email" placeholder="Your email address" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField label="First Name" placeholder="Your first name" />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField label="Password" placeholder="Your password" />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField label="Last Name" placeholder="Your last name" />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField label="Confirm Password" placeholder="Your password" />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField label="Preferred Username" placeholder="Username" />
                            </Grid>
                        </Grid>
                        <VuiBox mt={4} mb={1} textAlign="center">
                            <VuiButton color="info" onClick={handleFormSubmission}>
                                SIGN UP
                            </VuiButton>
                        </VuiBox>
                        <VuiBox mt={3} textAlign="center">
                            <VuiTypography variant="button" color="text" fontWeight="regular">
                                Already have an account?{" "}
                                <VuiTypography
                                    component={Link}
                                    to="/authentication/sign-in/aviai"
                                    variant="button"
                                    color="white"
                                    fontWeight="medium"
                                >
                                    Sign In
                                </VuiTypography>
                            </VuiTypography>
                        </VuiBox>
                    </VuiBox>
                }
            </GradientBorder>
        </BasicLayout >
    );
}

export default SignUpAviAi;
