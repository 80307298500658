import React from 'react';
import './RealTimeForecaster.css';

function RealTimeForecaster(props) {
  const { percentage } = props;

  const startAngle = 112.5;
  const endAngle = (360 * (percentage / 100)) + 90;
  const circumference = 2 * Math.PI * 16;
  const offset = circumference - ((startAngle - endAngle) * (circumference / 360));

  return (
    // <div className="relative w-36 h-36 transform scale-120"> // original - Shara changed
    <div className="relative w-36 h-36">
      {/* The background circle */}
      <div className="absolute top-0 left-0 w-36 h-36 rounded-full bg-black" />

      {/* The progress bar */}
      <svg
        className="absolute top-0 left-0 w-36 h-36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#10B981" />
            <stop offset="100%" stopColor="#10B981" stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* The outer circle */}
        <circle
          cx="18"
          cy="18"
          r="16"
          strokeWidth="4"
          stroke="#000000"
          strokeLinecap="round"
          fill="transparent"
        />

        {/* The progress bar */}
        <circle
          cx="18"
          cy="18"
          r="16"
          strokeWidth="4"
          stroke={`url(#gradient)`}
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          transform={`rotate(${startAngle} 18 18)`}
          style={{ transition: 'stroke-dashoffset 1s' }}
        />
      </svg>

      {/* The percentage value */}
      <div className="absolute text-container w-full h-full flex flex-col items-center justify-center text-center">
      {/* <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center"> */}
        <div className="text-4xl font-bold text-white">{percentage}%</div>
        <div className="text-xs font-normal text-white">Prevention</div>
      </div>
    </div>
  );
}

export default RealTimeForecaster;
