/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import colors from "assets/theme/base/colors";

//theme colors
const { success, info } = colors;

export const mixedChartData = [
    {
        name: "# Bags Saved",
        type: "bar",
        data: [180, 120, 150, 190, 350, 210, 190, 250, 190, 210, 290, 300],
    },
    {
        name: "# Bags Predicted",
        type: "area",
        data: [210, 280, 280, 400, 500, 400, 350, 400, 270, 300, 450, 500],
    },
];

export const mixedChartOptions = {
    chart: {
        toolbar: {
            show: false,
        },
    },
    tooltip: {
        theme: "dark",
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: [4, 2],
    },
    xaxis: {
        type: "datetime",
        categories: [
            "7/5",
            "8/5",
            "9/5",
            "10/5",
            "11/5",
            "12/5",
            "13/5",
            "14/5",
            "15/5",
            "16/5",
            "17/5",
            "18/5",
        ],
        labels: {
            style: {
                colors: "#A0AEC0", // "#10B981"
                fontSize: "12px",
            },
        },
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: "#A0AEC0",
                fontSize: "12px",
            },
        },
    },
    legend: {
        show: false,
    },
    grid: {
        borderColor: "#56577A",
        strokeDashArray: 2,
        yaxis: {
            lines: {
                show: true,
            },
        },
        xaxis: {
            lines: {
                show: false,
            },
        },
    },
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.1,
            
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [],
        },
        // colors: [success.main, 'rgba(183, 29, 255, 1)'],
        colors: [success.main, 'rgba(183, 29, 255, 1)'],
    },
    plotOptions: {
        bar: {
            borderRadius: 2,
            columnWidth: "8px",
        },
    },
    colors: ["#10B981", info.main],
};
